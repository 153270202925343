import { formatDate, formatPrice } from "../helpers/common";
import { ORDER_STATUS } from "../config/global";
import get from "lodash/get";

const orderMixin = {
  methods: {
    orderDate(order) {
      return formatDate(new Date(order.effective_date), "dd-MM-yyyy · HH:mm");
    },

    orderScheduledDelivery(order) {
      return formatDate(new Date(order.ddmi.delivery_date), "dd/MM/yyyy");
    },

    orderMarketName(order) {
      return get(order, "market.role.name", "--");
    },

    orderTotal(order, currencySymbol) {
      const total = get(order, "view.tot_price");
      return formatPrice(total, currencySymbol).format();
    },

    orderTotalOrig(order, currencySymbol) {
      const total = get(order, "view.tot_price_orig");
      return formatPrice(total, currencySymbol).format();
    },

    orderTotalIsChanged(order) {
      return get(order, "view.tot_price") !== get(order, "view.tot_price_orig");
    },

    isDeliverOrder(order) {
      const type = get(order, "ddmi.island.i_type", 0);
      return [1, 2].includes(type);
    },

    isWithdrawalOrder(order) {
      const type = get(order, "ddmi.island.i_type", 0);
      return [3].includes(type);
    },

    orderDeliveryLabel(order) {
      return this.isDeliverOrder(order) ? this.$t('family.orders.order.label_delivery') : this.$t('family.orders.order.label_pickup');
    },

    orderDeliveryName(order) {
      return get(order, "ddmi.island.role.name");
    },

    orderFamilyName(order) {
      return get(order, "family.name");
    },

    orderStatus(order) {
      const view = get(order, "view");
      if (!view) {
        return { label: "", color: "" };
      }
      const { tot_prods, tot_status } = view;
      const {
        CREATE,
        PAYMENT_WAIT,
        APPROVAL,
        CANCELED,
        ALERT,
        PRODUCER_IN_CHARGE,
        MARKET_ARRIVED,
        MARKET_STOCK,
        MARKET_IN_CHARGE,
        ISLAND_ARRIVED,
        ISLAND_IN_CHARGE,
        FAMILY_IN_CHARGE,
        DELIVERED,
      } = ORDER_STATUS;

      if (
        tot_status[CREATE] ||
        tot_status[PAYMENT_WAIT] ||
        tot_status[APPROVAL]
      ) {
        return { label: this.$t('family.orders.order.status.approve'), color: "info" };
      }

      if (tot_status[CANCELED] === tot_prods) {
        return { label: this.$t('family.orders.order.status.cancelled'), color: "danger" };
      }

      if (tot_status[ALERT]) {
        return { label: this.$t('family.orders.order.status.newincident'), color: "warning" };
      }

      if (
        tot_status[PRODUCER_IN_CHARGE] ||
        tot_status[MARKET_ARRIVED] ||
        tot_status[MARKET_STOCK] ||
        tot_status[MARKET_IN_CHARGE]
      ) {
        return { label: this.$t('family.orders.order.status.working'), color: "success" };
      }

      if (tot_status[ISLAND_ARRIVED]) {
        return { label: this.$t('family.orders.order.status.moving'), color: "blue" };
      }

      if (tot_status[ISLAND_IN_CHARGE]) {
        return {
          label: `${
            this.isDeliverOrder(order) ? this.$t('family.orders.order.status.shipped') : this.$t('family.orders.order.status.pickup')
          }`,
          color: "blue",
        };
      }

      if (tot_status[FAMILY_IN_CHARGE]) {
        return {
          label: `${this.isDeliverOrder(order) ? this.$t('family.orders.order.status.delivered') : this.$t('family.orders.order.status.pickedup')}`,
          color: "success",
        };
      }

      if (tot_status[DELIVERED]) {
        return { label: this.$t('family.orders.order.status.completed'), color: "success" };
      }

      return { label: "", color: "" };
    },
  },
};

export default orderMixin;
