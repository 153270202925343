<template>
  <CCard>
    <CCardBody>
      <CRow>
        <CCol col="auto" md="3">
          <div class="ui-type-caption text-uppercase">{{ $t('family.orders.order.label') }}</div>
          <div class="ui-type-display-sm">#{{ order.id }}</div>
          <MarketOrderStatusBadge :order="order" />
        </CCol>
        <CCol col="auto" :lg="{ order: '2' }" class="ml-auto">
          <div class="d-flex flex-column">
            <vue-ladda
              :loading="loading.details"
              data-style="zoom-in"
              data-spinner-color="#000"
              button-class="btn btn-primary text-uppercase d-block mb-2"
              @click.prevent="onViewDatails()"
              >{{ $t('family.orders.order.btn_details') }}</vue-ladda
            >
            <vue-ladda
              v-if="messageButton"
              :loading="loading.messages"
              data-style="zoom-in"
              data-spinner-color="#000"
              button-class="btn btn-outline-primary text-uppercase d-block"
              @click.prevent="onMessage()"
              >{{ $t('family.orders.order.btn_incident') }}</vue-ladda
            >
          </div>
        </CCol>
        <CCol col="12" lg>
          <CRow>
            <CCol>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">{{ $t('family.orders.order.date_order') }}</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderDate(order) }}
                </div>
              </div>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">
                  {{ $t('family.orders.order.date_delivery') }}
                </div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderScheduledDelivery(order) }}
                </div>
              </div>
            </CCol>
            <CCol>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">{{ $t('family.orders.order.price_tot') }}</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderTotal(order, currencySymbol) }}
                  <del v-if="orderTotalIsChanged(order)">{{
                    orderTotalOrig(order, currencySymbol)
                  }}</del>
                </div>
              </div>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">
                  {{ orderDeliveryLabel(order) }}
                </div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderDeliveryName(order) }}
                </div>
              </div>
            </CCol>
            <CCol>
              <div class="mb-4">
                <div class="ui-type-caption text-uppercase">{{ $t('family.orders.order.market') }}</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderMarketName(order) }}
                </div>
              </div>
              <div class="mb-4" v-if="showFamilyName">
                <div class="ui-type-caption text-uppercase">{{ $t('family.orders.order.family') }}</div>
                <div class="ui-type-body font-weight-bold">
                  {{ orderFamilyName(order) }}
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <ejs-grid ref="grid" :dataSource="rows" v-show="showDetails">
        <e-columns>
          <e-column
            field="id"
            headerText=""
            isPrimaryKey="{true}"
            :template="imageTemplate"
            width="90"
            textAlign="Center"
          ></e-column>
          <e-column :template="nameTemplate" :headerText="$t('models.productproducer.name')"></e-column>
          <e-column
            :template="producerTemplate"
            width="240"
            :headerText="$t('models.productproducer.producer')"
          ></e-column>
          <e-column
            :template="quantityTemplate"
            :headerText="$t('models.cart.qta')"
            textAlign="right"
            width="100"
          ></e-column>
          <e-column :template="statusTemplate" width="150"></e-column>
          <e-column
            :template="totalTemplate"
            textAlign="right"
            :headerText="$t('models.cart.total')"
            width="120"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </CCardBody>
  </CCard>
</template>

<script>
import Vue from "vue"
import { GetDataManagerNew, ApiCall } from "../../../ds";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import orderMixin from "../../../mixins/order";
import MarketOrderStatusBadge from "./MarketOrderStatusBadge";
// import MarketOrderTableVariantImage from "./MarketOrderTableVariantImage";
// import MarketOrderTableVariantProducer from "./MarketOrderTableVariantProducer";
// import MarketOrderTableVariantQuantity from "./MarketOrderTableVariantQuantity";
// import MarketOrderTableVariantStatus from "./MarketOrderTableVariantStatus";
// import MarketOrderTableVariantTitle from "./MarketOrderTableVariantTitle";
// import MarketOrderTableVariantTotal from "./MarketOrderTableVariantTotal";
import EventBus from "../../../helpers/EventBus";
import { ORDER_STATUS, INCIDENT_ACTIONS } from "../../../config/global";
import { getTranslation, getOptionLabel } from "../../../helpers/common";
import { getPriceUnitOptions, getPwOptions } from "../../../helpers/options";
import { formatPrice } from "../../../helpers/common";
import get from "lodash/get";

export default {
  name: "MarketOrder",

  mixins: [orderMixin],

  components: {
    MarketOrderStatusBadge,
  },

  props: {
    order: { type: Object, required: true },
    type: { type: String, default: "family" },
    viewDetails: { type: Boolean, default: false },
    messageButton: { type: Boolean, default: true },
    showFamilyName: { type: Boolean, default: false },
  },

  data() {
    return {
      showDetails: false,

      loading: {
        details: false,
        messages: false,
      },

      rows: [],


      imageTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <figure class="table-figure">
                  <img class="border rounded" :src="getImage()" height="50" />
                </figure>
              </div>`,

            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },

            methods: {
              getImage() {
                const image = get(this.data, `ppm.img.image_thumb`);
                if (image) {
                  return image;
                }
                return "https://picsum.photos/36";
              },
            },
          }),
        };
      },
      // imageTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketOrderTableVariantImage,
      //     },
      //   };
      // },


      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
                <strong>{{ getProductName() }}</strong>
                <div>{{ getVariantName() }}</div>
              </div>`,

            computed: {
              lang: function () {
                return this.$i18n.locale;
              },
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },
            methods: {
              getProductName() {
                const translations = get(
                  this.data,
                  "ppm.lot.productproducer.translations"
                );
                return getTranslation(translations, this.lang);
              },
              getVariantName() {
                const translations = get(this.data, "ppm.lot.translations");
                return getTranslation(translations, this.lang);
              },
            },
          }),
        };
      },
      // nameTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketOrderTableVariantTitle,
      //     },
      //   };
      // },


      quantityTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="text-right" :class="{ opacity50: disabled }">
                <div class="ui-type-body font-weight-bold">
                  <span class="mr-2" :class="{ strikethrough: disabled }">{{
                    presentQuatity()
                  }}</span>
                </div>
              </div>`,

            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },
            },
            methods: {
              suffix() {
                const pp = get(this.data, "ppm.lot.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                if (pw_option === 3) {
                  return `${getOptionLabel(pp.pw_unit, getPriceUnitOptions(this.$i18n))}`;
                } else {
                  return `${getOptionLabel(pw_option, getPwOptions(this.$i18n), "short")}`;
                }
              },

              presentQuatity() {
                return `${this.data.qta} ${this.suffix()}`;
              },
            },
          }),
        };
      },
      // quantityTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketOrderTableVariantQuantity,
      //     },
      //   };
      // },

      statusTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <div
                  v-if="status.label !== ''"
                  :class="getBadgeClasses()"
                  @click.prevent="onClick"
                >
                  {{ status.label }}
                </div>
                <div v-if="status.detail">{{ status.detail }}</div>
              </div>`,

            computed: {
              status() {
                return this.orderDetailStatus(this.data);
              },
              openIncidentDetails() {
                return true
              }
            },

            methods: {
              pwUnit() {
                const pp = get(this.data, "ppm.lot.productproducer");
                const pw_option = get(pp, "extra.pw_option", 1);
                if (pw_option === 3) {
                  return `${getOptionLabel(pp.pw_unit, getPriceUnitOptions(this.$i18n))}`;
                } else {
                  return `${getOptionLabel(pw_option, getPwOptions(this.$i18n), "short")}`;
                }
              },

              orderDetailStatus(orderDetail) {
                const { status, incident } = orderDetail;
                const {
                  CREATE,
                  PAYMENT_WAIT,
                  APPROVAL,
                  CANCELED,
                  ALERT,
                  PRODUCER_IN_CHARGE,
                  MARKET_ARRIVED,
                  MARKET_IN_CHARGE,
                  ISLAND_ARRIVED,
                  ISLAND_IN_CHARGE,
                  FAMILY_IN_CHARGE,
                  DELIVERED,
                } = ORDER_STATUS;

                // incident
                if (incident !== null) {
                  if (
                    get(incident, "detail.extra.action", "") === "" ||
                    get(incident, "close", false) === false
                  ) {
                    return { label: this.$t('family.orders.order.incident.working'), color: "warning" };
                  }

                  if (
                    get(incident, "detail.extra.action", "") === INCIDENT_ACTIONS.DISCOUNT
                  ) {
                    return { label: this.$t('family.orders.order.incident.discount'), color: "blue", detail: "" };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.REPLACEMENT
                  ) {
                    return {
                      label: this.$t('family.orders.order.incident.replaced'),
                      color: "blue",
                      detail: get(incident, "detail.extra.replacedWith", ""),
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.CHANGE_WEIGHT
                  ) {
                    return {
                      label: this.$t('family.orders.order.incident.change_weight'),
                      color: "warning",
                      detail: `${get(
                        incident,
                        "detail.extra.newWeight",
                        ""
                      )} ${this.pwUnit()}`,
                    };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.CANCELLED
                  ) {
                    return { label: this.$t('family.orders.order.incident.deleted'), color: "danger", detail: "" };
                  }

                  if (
                    get(incident, "detail.extra.action", "") ===
                    INCIDENT_ACTIONS.CANCELLED_FOR
                  ) {
                    return { label: this.$t('family.orders.order.incident.deleted_for'), color: "warning", detail: "" };
                  }
                }

                // status

                if ([CREATE, PAYMENT_WAIT, APPROVAL].includes(status)) {
                  return { label: this.$t('family.orders.order.status.approve'), color: "info", detail: "" };
                }

                if ([CANCELED].includes(status)) {
                  return { label: this.$t('family.orders.order.status.cancelled'), color: "danger", detail: "" };
                }

                if ([ALERT].includes(status)) {
                  return { label: this.$t('family.orders.order.status.incident'), color: "warning", detail: "" };
                }

                if (
                  [PRODUCER_IN_CHARGE, MARKET_ARRIVED, MARKET_IN_CHARGE].includes(status)
                ) {
                  return { label: this.$t('family.orders.order.status.working'), color: "success", detail: "" };
                }

                if ([ISLAND_ARRIVED].includes(status)) {
                  return { label: this.$t('family.orders.order.status.moving'), color: "blue" };
                }

                if ([ISLAND_IN_CHARGE].includes(status)) {
                  return {
                    label: this.$t('family.orders.order.status.delivery'),
                    color: "blue",
                    detail: "",
                  };
                }

                if ([FAMILY_IN_CHARGE].includes(status)) {
                  return { label: this.$t('family.orders.order.status.delivery_done'), color: "success", detail: "" };
                }

                if ([DELIVERED].includes(status)) {
                  return { label: this.$t('family.orders.order.status.completed'), color: "success", detail: "" };
                }

                return { label: "", color: "", detail: "" };
              },

              getBadgeClasses() {
                const { status } = this;
                const classes = [`badge badge-${status.color}`];
                if (this.openIncidentDetails && this.haveIncident()) {
                  classes.push("cursor-pointer");
                }
                return classes.join(" ");
              },

              haveIncident() {
                return get(this.data, "incident", null) !== null;
              },

              onClick() {
                if (this.openIncidentDetails && this.haveIncident()) {
                  this.$store.commit("incidents/viewSetIncidentDetail", this.data);
                  this.$store.commit("incidents/viewShow");
                }
              },
            },
          }),
        };
      },
      // statusTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketOrderTableVariantStatus,
      //     },
      //   };
      // },

      producerTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<strong class="d-block text-truncate">{{ getProducerName() }}</strong>`,

            methods: {
              getProducerName() {
                return get(this.data, "ppm.lot.productproducer.producer.role.name");
              },
            },
          }),
        };
      },
      // producerTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketOrderTableVariantProducer,
      //     },
      //   };
      // },

      totalTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div :class="{ opacity50: disabled }">
              <div v-if="hasIncident()" class="ui-type-heading text-right strikethrough">
                {{ getPriceOrig() }}
              </div>
              <div
                class="ui-type-heading text-right"
                :class="{ strikethrough: strikeThrough }"
              >
                {{ getPrice() }}
              </div>
            </div>`,

            computed: {
              disabled() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },

              strikeThrough() {
                return this.data.status === ORDER_STATUS.CANCELED;
              },

              ...mapGetters("connections", ["currencySymbol"]),
            },

            methods: {
              getPrice() {
                const price = get(this.data, "price", null);
                return price !== null
                  ? formatPrice(price, this.currencySymbol).format()
                  : "";
              },

              hasIncident() {
                return get(this.data, "incident", null) !== null;
              },

              getPriceOrig() {
                const price = get(this.data, "incident.detail.orig_price", null);
                return price !== null
                  ? formatPrice(price, this.currencySymbol).format()
                  : "";
              },
            },
          }),
        };
      },
      // totalTemplate: () => {
      //   return {
      //     template: {
      //       extends: MarketOrderTableVariantTotal,
      //     },
      //   };
      // },
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol", "familyId"]),
  },

  mounted() {
    if (this.viewDetails) {
      this.onViewDatails();
    }
    EventBus.$on("messages:created", this.reloadDetails);
  },

  beforeDestroy() {
    EventBus.$off("messages:created", this.reloadDetails);
  },

  methods: {
    onViewDatails() {
      if (this.showDetails === false) {
        if (this.rows.length === 0) {
          this.loading.details = true;
          this.getOrderDetails().then(() => {
            this.loading.details = false;
            this.showDetails = true;
          });
        } else {
          this.showDetails = true;
        }
      } else {
        this.showDetails = false;
      }
    },

    reloadDetails(order) {
      if (order.id === this.order.id) {
        this.loading.details = true;
        this.getOrderDetails().then(() => {
          this.loading.details = false;
        });
      }
    },

    onMessage() {
      this.loading.messages = true;
      if (this.rows.length === 0) {
        this.getOrderDetails().then(() => {
          this.openMessageModal();
        });
      } else {
        this.openMessageModal();
      }
    },

    getDataManagerInstance() {
      if (this.type === "family") {
        return GetDataManagerNew("role_order_family_details", [
          this.familyId,
          this.order.id,
        ]);
      }
      if (this.type === "role") {
        const roleId = this.$store.state.role.id;
        return GetDataManagerNew("role_order_role_details", [
          roleId,
          this.order.id,
        ]);
      }
      return null;
    },

    getOrderDetails() {
      const self = this;
      const query = new Query();
      const dm = this.getDataManagerInstance();
      if (!dm) return;
      return ApiCall(
        dm,
        query,
        (response) => {
          self.rows = response.result;
          return response;
        },
        (e) => {
          console.error(e);
          self.loading.details = false;
        }
      );
    },

    openMessageModal() {
      this.loading.messages = false;
      const paylod = {
        order: this.order,
        details: this.rows,
        show: true,
      };
      this.$store.commit("messages/openModal", paylod);
    },
  },
};
</script>
